import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { signIn, fbSignIn, signUp } from "../../store/actions";
import { Button, Grid, Header, Icon } from "semantic-ui-react";
import LoginWrapper from "../../hoc/LoginWrapper/LoginWrapper";
import LoginForm from "../../components/Login/LoginForm";
import SignUpForm from "../../components/Login/SignUpForm";
import ForgotPassword from "../../components/Login/ForgotPassword";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { withNamespaces } from "react-i18next";
import "./style.css";

class Login extends Component {
  state = {
    redirectToReferrer: false,
    email: "",
  };

  changeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  componentDidMount() {
    if (this.props.user) this.props.history.push("main");
  }
  signInCompleteHandler = (success) => {
    this.setState({ redirectToReferrer: success });
  };
  facebookSignIn = (user) => {
    if (user.id) {
      this.props.fbSignIn(user);
      this.signInCompleteHandler(true);
    }
  };

  render() {
    const {
      t,
      lng,
      location,
      signInLoading,
      signIn,
      signUp,
      mode,
    } = this.props;
    let { redirectToReferrer, email } = this.state;


    let from = location.state && location.state.search ? location.state : (this.props.user && this.props.user.is_corp_admin ? { pathname: "/" } : { pathname: "/main" });
    if (redirectToReferrer || this.props.user) return <Redirect to={from.pathname} />;

    return (
      <LoginWrapper loading={signInLoading} t={t}>
        <Grid style={{ display: "block", height: "100%" }}>
          <Grid.Row columns={1}>
            <Grid
              className="py-2"
              stackable={false}
              columns={2}
              verticalAlign="middle"
              divided
            >
              <Grid.Column>
                <Header
                  size="medium"
                  onClick={() => this.props.history.push("/login")}
                  disabled={mode !== "login"}
                  textAlign="right"
                  style={{ cursor: "pointer" }}
                >
                  {t("login.login")}
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header
                  size="medium"
                  textAlign="left"
                  onClick={() => this.props.history.push("/signup")}
                  style={{ cursor: "pointer" }}
                  disabled={mode !== "signup"}
                >
                  {t("login.sign_up")}
                </Header>
              </Grid.Column>
            </Grid>
          </Grid.Row>
          {mode !== "forget" ? (
            <Grid.Row
              columns={1}
              centered
              style={{ maxWidth: "29em", padding: "2em" }}
            >
              {mode === "login" ? (
                <LoginForm
                  signIn={signIn}
                  signInSucceeded={this.signInCompleteHandler}
                  emailHandler={(email) => this.setState({email})}
                />
              ) : (
                <SignUpForm
                  signUp={signUp}
                  signUpSucceeded={this.signInCompleteHandler}
                />
              )}
              <div
                className="my-1"
                style={{
                  color: "#999A9B",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {t("login.or")}
              </div>
              <FacebookLogin
                appId="123797858331210"
                fields="first_name, last_name, gender ,email,picture"
                language={lng}
                textButton={t("login.with_fb")}
                callback={this.facebookSignIn}
                render={(renderProps) => (
                  <Button
                    className="rounded"
                    color="facebook"
                    size="large"
                    onClick={renderProps.onClick}
                    labelPosition="left"
                    icon
                    fluid
                  >
                    <Icon name="facebook f" size="large" />
                    {t("login.with_fb")}
                  </Button>
                )}
              />
            </Grid.Row>
          ) : (
            <Grid.Row
              columns={1}
              centered
              style={{ maxWidth: "29em", padding: "2em" }}
            >
              <ForgotPassword
                email={email}
                emailHandler={(email) => this.setState({ email })}
              />
            </Grid.Row>
          )}
          {mode === "login" && (
            <Grid.Row
              centered
              style={{ bottom: "1em", position: "absolute", cursor: "pointer" }}
              onClick={() => this.props.history.push("/forget-password")}
            >
              <Header as="h4" color="grey">
                {t("login.forgot_password")}
              </Header>
            </Grid.Row>
          )}
        </Grid>
      </LoginWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    signInLoading:
      state.loading["SIGN_IN"] ||
      state.loading["FB_SIGN_IN"] ||
      state.loading["SIGN_UP"] ||
      state.loading["FORGET_PASSWORD"],
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (email, password) => dispatch(signIn(email, password)),
    fbSignIn: (email, password) => dispatch(fbSignIn(email, password)),
    signUp: (data) => dispatch(signUp(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(Login));
