import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';


const calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight) => {

  var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

  return { width: srcWidth * ratio, height: srcHeight * ratio };
}

export const print = (mode = { autoPrint: false }, ids, filename = "download") => {
  document.body.style.width="1440px";
  const pdf = new jsPDF("p", "mm", "a4");
  const width = pdf.internal.pageSize.getWidth() - 20;
  const height = pdf.internal.pageSize.getHeight() - 20;
  return Promise.all(ids.map(
    id => html2canvas(document.getElementById(id),{windowWidth:1440,width:1161,scale:2.205}).then(canvas => (
      {
        imgData: canvas.toDataURL('image/jpeg', 1),
        width: canvas.width,
        height: canvas.height
      }
    )))
  ).then(images => {
    images.forEach((image, index) => {
      console.log(image.width)
      console.log(image.height)
      console.log(width)
      console.log(height)
      const sizes = calculateAspectRatioFit(image.width, image.height, width, height);
      console.log(sizes)
       pdf.addImage(image.imgData, 'JPEG', 10 + (width - sizes.width) / 2, 20, sizes.width, sizes.height);
      if (index !== images.length - 1) pdf.addPage("p", "mm", "a4");
      document.body.style.width="";  
    }) 
      
    if (mode.autoPrint) {
      pdf.autoPrint();
      pdf.output('dataurlnewwindow')
    }
    else {
      pdf.save(filename+".pdf");
    }
  })
}