import React from 'react';
import { Menu } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

export default withNamespaces()(({ user }) => 

  <>
    <Menu.Item>
      <NavLink to="/info" style={{color:"black"}}><b> Meslekİlgi Modülü </b> <span> Nedir?</span></NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink to="/who-can-use" style={{color:"black"}}><span>Kimler Kullanabilir? </span></NavLink>
    </Menu.Item>
       {/* <Menu.Item> 
      <NavLink to="/how-much" style={{color:"black"}}><span>Ne Kadar? </span></NavLink> 
    </Menu.Item>  */}
    {/* if user logged in search engine will appear only then */}
    
    {user && 
    <Menu.Item>
      <NavLink to="/search-program" style={{color:"black"}}><span>Meslek ara? </span></NavLink>
    </Menu.Item>}
    
    <Menu.Item >
      <NavLink to="/membership" style={{color:"black"}}><span>Üyelik </span></NavLink>
    </Menu.Item>
    
    <Menu.Item >
      <NavLink to="/faq" style={{color:"black"}}><span>S.S.S. </span></NavLink>
    </Menu.Item>
  </>
  
)

