import React, { Component } from "react";
import { Segment, Header } from 'semantic-ui-react';
import { Bar as HorizontalBar } from "react-chartjs-2";
import { Chart } from 'chart.js';

export default class ResultBarChart extends Component {
  componentDidMount() {
    // this.refs.chart.chartInstance.options.scales.yAxes[0].ticks.backdropColor = "black"
  }
  componentDidUpdate() {
  }
  render() {
    const { data } = this.props;
    const labels = data.map(item => item.name);
    const chartData = {
      labels: data.map(item => item.name),
      datasets: [
        {
          data: data.map(item => this.props.placeholder ? 55 : item.value),
          backgroundColor: data.map((item) => item.color)
        }
      ]
    };
    const options = {
      layout:{
        padding:{
          left:0,
          right:0,
          top: 25,
          bottom:0
        }
      },
      legend: {
        display: false
      },
      hover: {
        animationDuration: 0
      },
      scales: {
        xAxes: [{
          gridLines: {
            lineWidth: 0
          },
          ticks: {
            display: true,
          },
        }],
        yAxes: [{
          ticks: {
            display: false,
            max:60,
            min: 0
            
          },
          gridLines: {
            drawBorder: false
          }
        }]
      },
      tooltips: {
        enabled: false
      },
      animation: {
        duration: 0,
        onComplete: function () {
          var chartInstance = this.chart,
            ctx = chartInstance.ctx;
          ctx.textAlign = 'center';
          ctx.fillStyle = "#7F7F7F";
          ctx.textBaseline = 'bottom';
          ctx.font = Chart.helpers.fontString(13, 'normal', Chart.defaults.global.defaultFontFamily);

          this.data.datasets.forEach(function (dataset, i) {
            var meta = chartInstance.controller.getDatasetMeta(i);
            meta.data.forEach(function (bar, index) {
              var data = dataset.data[index].toFixed(2);
              ctx.fillText(data, bar._model.x, bar._model.y - 5);
            });
          });
        }
      }
    };

    return (<Segment id={this.props.id} style={{ display: "flex", flexDirection: "column" }}>
      <Header className="ReportHeader" size="large" content="Genel Kişilik Tipi Dağılımı" />
      <div style={{ flexGrow: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <HorizontalBar data={chartData} options={options} ref='chart' />
      </div>
    </Segment>);




  }
}
