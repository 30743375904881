import React, { Component } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { formDataUpdate } from '../../helpers/validation';
import { withNamespaces } from 'react-i18next';

class SignUp extends Component {
    state = {
      formElements:{
        firstName:{
          value:"",
          validityRules:{
            minLength:2
          }
        },
        lastName:{
          value:"",
          validityRules:{
            minLength:2
          }
        },
        email:{
          value:"",
          validityRules:{
            isEmail:true
          }
        },
        password:{
          value:"",
          validityRules:{
            minLength:6
          }
        },
        confirmPassword:{
          value:""
        },

      }};
      changeHandler = event => {
        const {name,value} = event.target;

        this.setState({formElements: formDataUpdate(this.state.formElements,value,name)});
      };
      signUp = () => {

       const  data = {
         firstName:this.state.formElements.firstName.value,
         lastName:this.state.formElements.lastName.value,
         email:this.state.formElements.email.value,
         password:this.state.formElements.password.value
       }
        this.props.signUp(data).then(this.props.signUpSucceeded);
      };
      checkFormValidity = () => {
        return ["firstName","lastName","email","password","confirmPassword"].map(key =>this.state.formElements[key].isValid).every(valid=>valid)
      }
    render(){
    const {t} = this.props;
    const {firstName,lastName,email,password,confirmPassword} = this.state.formElements;
    return (
      <Form size="large">
        <Form.Input
          className="rounded"
          fluid
          icon="user"
          iconPosition="left"
          placeholder={t('first_name')}
          name="firstName"
          onChange={this.changeHandler}
          value={firstName.value}
          error={firstName.touched&&!firstName.isValid}
        />
        <Form.Input
          className="rounded"
          fluid
          icon="user"
          iconPosition="left"
          placeholder={t('last_name')}
          name="lastName"
          onChange={this.changeHandler}
          value={lastName.value}
          error={lastName.touched&&!lastName.isValid}
        />
        <Form.Input
          className="rounded"
          fluid
          icon="mail"
          iconPosition="left"
          placeholder="Email"
          name="email"
          type="email"
          onChange={this.changeHandler}
          value={email.value}
          error={email.touched&&!email.isValid}
        />
        <Form.Input
          className="rounded"
          fluid
          icon="lock"
          name="password"
          iconPosition="left"
          placeholder={t('password')}
          type="password"
          onChange={this.changeHandler}
          value={password.value}
          error={password.touched&&!password.isValid}
        />
        <Form.Input
          className="rounded"
          fluid
          icon="lock"
          name="confirmPassword"
          iconPosition="left"
          placeholder={t('password_re')}
          type="password"
          onChange={this.changeHandler}
          value={confirmPassword.value}
          error={confirmPassword.touched&&!confirmPassword.isValid}
        />
        <Button
          className="rounded"
          fluid
          secondary
          size="large"
          onClick={this.signUp}
          disabled={!this.checkFormValidity()}
          style={{ backgroundColor: "#38BA9B"}}
        >
        {t('login.sign_up')}
        </Button>
      </Form>)
    }
}

export default withNamespaces()(SignUp);
