import {
  GET_TEST_REQUEST,
  GET_TEST_SUCCESS,
  GET_TEST_FAIL,
  CHECKOUT_REQUEST,
  CHECKOUT_SUCCESS,
  CHECKOUT_FAIL,
  SUBMIT_ANSWERS_REQUEST,
  SUBMIT_ANSWERS_SUCCESS,
  SUBMIT_ANSWERS_FAIL,
  SUBMIT_TEST_REQUEST,
  SUBMIT_TEST_SUCCESS,
  SUBMIT_TEST_FAIL,
  SUBMIT_JOBS_REQUEST,
  SUBMIT_JOBS_SUCCESS,
  SUBMIT_JOBS_FAIL,
  RESET_TEST_REQUEST,
  RESET_TEST_SUCCESS,
  RESET_TEST_FAIL,
  VALIDATE_COUPON_REQUEST,
  VALIDATE_COUPON_SUCCESS,
  VALIDATE_COUPON_FAIL,
} from "./actionTypes";
import { showNotification } from "./ui";

import axios from "../../config/axios-instance";
import api from "../../config/api";

const getTestRequest = () => {
  return {
    type: GET_TEST_REQUEST,
  };
};

const getTestSuccess = (test) => {
  return {
    type: GET_TEST_SUCCESS,
    test,
  };
};

const getTestFail = () => {
  return {
    type: GET_TEST_FAIL,
  };
};

export const getTest = () => {
  return (dispatch) => {
    dispatch(getTestRequest());
    return axios
      .get(api.get_test, { params: { test_key: "inventory" } })
      .then((response) => {
        dispatch(getTestSuccess(response.data));
        dispatch(
          showNotification({
            message: "get test completed completed",
            positive: true,
          })
        );
      })
      .catch((error) => {
        dispatch(getTestFail());
        dispatch(showNotification({ message: error.message, negative: true }));
      });
  };
};
const checkoutRequest = () => {
  return {
    type: CHECKOUT_REQUEST,
  };
};
const checkoutSuccess = (data) => {
  return {
    type: CHECKOUT_SUCCESS,
    data,
  };
};
const checkoutFail = () => {
  return {
    type: CHECKOUT_FAIL,
  };
};

export const checkout = (test_id, user_capacity, appliedCoupon) => (
  dispatch
) => {
  dispatch(checkoutRequest());
  return axios
    .post(api.checkout, {
      test_id,
      user_capacity,
      appliedCoupon,
    })
    .then((res) => {
      dispatch(checkoutSuccess(res.data));
      dispatch(showNotification({ message: "successful", positive: true }));
      return res.data;
    })
    .catch((error) => {
      dispatch(checkoutFail());
      dispatch(showNotification({ message: error.message, negative: true }));
    });
};

const submitAnswersRequest = () => {
  return {
    type: SUBMIT_ANSWERS_REQUEST,
  };
};

const submitAnswersSuccess = () => {
  return {
    type: SUBMIT_ANSWERS_SUCCESS,
  };
};

const submitAnswersFail = () => {
  return {
    type: SUBMIT_ANSWERS_FAIL,
  };
};

export const submitAnswers = (answers) => (dispatch) => {
  dispatch(submitAnswersRequest());
  return axios
    .post(api.submit_answers, { answers })
    .then((res) => {
      dispatch(submitAnswersSuccess());
      console.log("submitted", answers.map((answer) => answer.question_id));
      return res.data;
    })
    .catch((error) => {
      dispatch(submitAnswersFail());
      dispatch(showNotification({ message: error.message, negative: true }));
    });
};

// export const submitAnswers2 = (answers) => async  dispatch => {
//   const error = Math.random() < .51;
//   let value = "";
//   if (!error)
//     value = await new Promise((resolve, reject) => {
//       setTimeout(() => resolve("done!"), 3000)
//     });
//   else
//     return console.log("not submitted", answers.map(answer => answer.question_id));
//   return submitAnswers(answers)(dispatch);
// }

const submitTestRequest = () => {
  return {
    type: SUBMIT_TEST_REQUEST,
  };
};

const submitTestSuccess = (results) => {
  return {
    type: SUBMIT_TEST_SUCCESS,
    results,
  };
};

const submitTestFail = () => {
  return {
    type: SUBMIT_TEST_FAIL,
  };
};

export const submitTest = () => (dispatch) => {
  dispatch(submitTestRequest());
  return axios
    .post(api.submit_test, { test_key: "inventory" })
    .then((res) => {
      dispatch(submitTestSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      dispatch(submitTestFail());
      dispatch(showNotification({ message: error.message, negative: true }));
      return Promise.reject();
    });
};

const submitJobsRequest = () => {
  return {
    type: SUBMIT_JOBS_REQUEST,
  };
};

const submitJobsSuccess = (jobs) => {
  return {
    type: SUBMIT_JOBS_SUCCESS,
    jobs,
  };
};

const submitJobsFail = () => {
  return {
    type: SUBMIT_JOBS_FAIL,
  };
};

export const submitJobs = (jobs) => (dispatch) => {
  dispatch(submitJobsRequest());
  return axios
    .post(api.submit_jobs, { jobs })
    .then((res) => {
      dispatch(submitJobsSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      dispatch(submitJobsFail());
      dispatch(showNotification({ message: error.message, negative: true }));
      return Promise.reject();
    });
};

const resetTestRequest = () => ({
  type: RESET_TEST_REQUEST,
});

const resetTestSuccess = () => ({
  type: RESET_TEST_SUCCESS,
});

const resetTestFail = () => ({
  type: RESET_TEST_FAIL,
});

export const resetTest = () => (dispatch) => {
  dispatch(resetTestRequest());
  return axios
    .post(api.reset_test, { test_key: "inventory" })
    .then((response) => {
      dispatch(resetTestSuccess());
      return response.data;
    })
    .catch((error) => {
      dispatch(resetTestFail());
      dispatch(showNotification({ message: error.message, negative: true }));
      return Promise.reject();
    });
};

const validateCouponRequest = () => ({
  type: VALIDATE_COUPON_REQUEST,
});

const validateCouponSuccess = () => ({
  type: VALIDATE_COUPON_SUCCESS,
});

const validateCouponFail = () => ({
  type: VALIDATE_COUPON_FAIL,
});

export const validate_coupon = (code) => (dispatch, getState) => {
  dispatch(validateCouponRequest());
  return axios
    .get(api.validate_coupon, {
      params: { code, token: getState().auth.token },
    })
    .then((res) => {
      dispatch(validateCouponSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      dispatch(validateCouponFail());
    });
};
