import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import JSZip from 'jszip'
import { saveAs } from 'file-saver';
import html2pdf from 'html2pdf.js';

const calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight) => {

  var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

  return { width: srcWidth * ratio, height: srcHeight * ratio };
}


const scalefunc = (id) => {
  if(id=="page-1"){
    return 2.205
  }
  else{
    return 1
  }
}

export const printCorp = (mode = { autoPrint: false }, ids, filename = "download") => {
  document.body.style.width="1440px";
  const pdf = new jsPDF("p", "mm", "a4");
  var zip=new JSZip();
  const element = document.getElementById('user-list');
  const file = html2pdf(element).set({
    pagebreak: {avoid: 'tr' },
  }).output('blob')
  zip.file(filename+"-Öğrenci-Listesi.pdf",file)
  //save(filename+"-Öğrenci-Listesi.pdf");
  return Promise.all(ids.map(
    id => html2canvas(document.getElementById(id),{windowWidth:1440,width:1161,scale:scalefunc(id)}).then(canvas => (
      {
        imgData: canvas.toDataURL('image/jpeg', 1),
        width: 190,
        height: canvas.height * 190 / canvas.width
      }
    )))
  ).then( images => {
    images.forEach((image, index) => {
      var position = index ? 0 : 30;
      pdf.addImage(image.imgData, 'JPEG', 10 , position, image.width, image.height+10);
      if (index !== images.length - 1) pdf.addPage("p", "mm", "a4");
      document.body.style.width="";  
    }) 
    if (mode.autoPrint) {
      pdf.autoPrint();
      pdf.output('dataurlnewwindow')
    }
    else {
      //pdf.save(filename+"-İstatistikler.pdf");
      zip.file(filename+"-İstatistikler.pdf",pdf.output('blob'))
    }
    zip.generateAsync({type:"blob"})
    .then(function(content) {
        saveAs(content, filename+"-Meslekİlgi.zip");
    });
  })
}