import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withNamespaces} from 'react-i18next';
import { getTest, getUserDetails } from '../../store/actions';
import { Redirect } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import Purchase from '../../components/Purchase/Purchase';

import './Main.css';

class Main extends Component {
  componentDidMount(){
    if(!this.props.test){
      this.props.getTest();
      this.props.getUserDetails();
    }
  }

  render(){
    // console.log("Main props->", this.props);
    const {t, testLoading, test, isCorpAdmin} = this.props;
      if(testLoading) return <h1 style={{margin:'auto'}}>{t('test.loading')}</h1>
      if(!test) return <h1 style={{margin:'auto'}}>{t('test.notFound')}</h1>
      if(!test.is_purchased)
        return (
          <Grid style={{flex:1}} verticalAlign="middle">
            <Grid.Column stretched>
              <Purchase />
            </Grid.Column>
          </Grid>)
      if(test.is_completed) return <Redirect to={"/report/"+this.props.user_id}/>
      else return <Redirect to="/test" />
  }
}

const mapStateToProps = state => {
    return {
        testLoading:state.loading["GET_TEST"],
        test:state.tests[0],
        user_id:state.auth.user.id,
        isCorpAdmin: state.auth.user.is_corp_admin
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getTest : () => dispatch(getTest()),
        getUserDetails: () => dispatch(getUserDetails())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withNamespaces()(Main));
