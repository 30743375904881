import React, { Component, PureComponent } from "react";
import { connect } from "react-redux";
import { signOut } from "../../store/actions";
import NavBar from "../../components/NavBar/NavBar";
import "./style.css";
import Footer from "../../components/Footer/Footer";

class Layout extends PureComponent {
  render() {
    const { history } = this.props;
    return (
      <div className="Layout" >
        <NavBar
          signout={this.props.signOut}
          user={this.props.user} history={history}>
        <main className="Content" >
          {this.props.children}
          {/* <div className="BackgroundImage" style={{backgroundImage:`url(${BGImage})`}}/> */}
        </main>
        <Footer user={this.props.user}/>
        </NavBar>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user:state.auth.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Layout);
