// AUTH ACTION TYPES
export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAIL = 'SIGN_IN_FAIL';

export const SIGN_OUT = 'SIGN_OUT';

export const FORGET_PASSWORD_REQUEST = 'FORGET_PASSWORD_REQUEST';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAIL = 'FORGET_PASSWORD_FAIL';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const POLICY_AGREED = "POLICY_AGREED";

export const EDIT_USER_REQUEST = "EDIT_USER_REQUEST";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAIL = "EDIT_USER_FAIL";

export const GET_USER_DETAILS_REQUEST = "GET_USER_DETAILS_REQUEST";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAIL = "GET_USER_DETAILS_FAIL";

export const SET_USER_DETAILS_REQUEST = "SET_USER_DETAILS_REQUEST";
export const SET_USER_DETAILS_SUCCESS = "SET_USER_DETAILS_SUCCESS";
export const SET_USER_DETAILS_FAIL = "SET_USER_DETAILS_FAIL";

// UI ACTION TYPES
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';

// TEST ACTIONS TYPES
export const GET_TEST_REQUEST = "GET_TEST_REQUEST";
export const GET_TEST_SUCCESS = "GET_TEST_SUCCESS";
export const GET_TEST_FAIL = "GET_TEST_FAIL";

export const CHECKOUT_REQUEST = "CHECKOUT_REQUEST";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_FAIL= "CHECKOUT_FAIL";

export const  SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_FAIL = 'SIGN_UP_FAIL';

export const SUBMIT_ANSWERS_REQUEST = "SUBMIT_ANSWERS_REQUEST";
export const SUBMIT_ANSWERS_SUCCESS = "SUBMIT_ANSWERS_SUCCESS";
export const SUBMIT_ANSWERS_FAIL = "SUBMIT_ANSWERS_FAIL";

export const GET_RESULTS_REQUEST ="GET_RESULTS_REQUEST";
export const GET_RESULTS_SUCCESS ="GET_RESULTS_SUCCESS";
export const GET_RESULTS_FAIL ="GET_RESULTS_FAIL";

export const SUBMIT_TEST_REQUEST = "SUBMIT_TEST_REQUEST";
export const SUBMIT_TEST_SUCCESS = "SUBMIT_TEST_SUCCESS";
export const SUBMIT_TEST_FAIL = "SUBMIT_TEST_FAIL";

export const SUBMIT_JOBS_REQUEST = "SUBMIT_JOBS_REQUEST";
export const SUBMIT_JOBS_SUCCESS = "SUBMIT_JOBS_SUCCESS";
export const SUBMIT_JOBS_FAIL = "SUBMIT_JOBS_FAIL";

export const RESET_TEST_REQUEST = "RESET_TEST_REQUEST";
export const RESET_TEST_SUCCESS = "RESET_TEST_SUCCESS";
export const RESET_TEST_FAIL = "RESET_TEST_FAIL";

//COUPON ACTIONS TYPES
export const VALIDATE_COUPON_REQUEST = "VALIDATE_COUPON_REQUEST";
export const VALIDATE_COUPON_SUCCESS = "VALIDATE_COUPON_SUCCESS";
export const VALIDATE_COUPON_FAIL = "VALIDATE_COUPON_FAIL";

//CORPORATION ACTIONS TYPES

export const CORPORATION_DETAILS_REQUEST = "CORPORATION_DETAILS_REQUEST";
export const CORPORATION_DETAILS_SUCCESS = "CORPORATION_DETAILS_SUCCESS";
export const CORPORATION_DETAILS_FAIL = "CORPORATION_DETAILS_FAIL";

export const GET_DIV_NAMES_AND_COUNTS_REQUEST  = "GET_DIV_NAMES_AND_COUNTS_REQUEST";
export const GET_DIV_NAMES_AND_COUNTS_SUCCESS  = "GET_DIV_NAMES_AND_COUNTS_SUCCESS";
export const GET_DIV_NAMES_AND_COUNTS_FAIL  = "GET_DIV_NAMES_AND_COUNTS_FAIL";

export const GET_DIVS_AND_USERS_REQUEST = "GET_DIVS_AND_USERS_REQUEST";
export const GET_DIVS_AND_USERS_SUCCESS = "GET_DIVS_AND_USERS_SUCCESS";
export const GET_DIVS_AND_USERS_FAIL = "GET_DIVS_AND_USERS_FAIL";

export const GET_CORPORATION_RESULTS_REQUEST = "GET_CORPORATION_RESULTS_REQUEST";
export const GET_CORPORATION_RESULTS_SUCCESS = "GET_CORPORATION_RESULTS_SUCCESS";
export const GET_CORPORATION_RESULTS_FAIL = "GET_CORPORATION_RESULTS_FAIL";

export const GET_CORPORATION_USER_RESULTS_REQUEST = "GET_CORPORATION_USER_RESULTS_REQUEST";
export const GET_CORPORATION_USER_RESULTS_SUCCESS = "GET_CORPORATION_USER_RESULTS_SUCCESS";
export const GET_CORPORATION_USER_RESULTS_FAIL = "GET_CORPORATION_USER_RESULTS_FAIL";
