import React from 'react';
import TypeDetailsCard from './TypeDetailsCard';
import { Grid } from 'semantic-ui-react';
import { withNamespaces } from 'react-i18next';
import { typeColor } from '../../../../helpers/typeColor';

export default withNamespaces()(({ t, types,placeholder, subTypes }) => {
  //const types = t("types", { returnObjects: true });
  
  return (
    <>
      {(placeholder?["social"]:types).map((type, i) => 
      <Grid.Row key={i}>
        <Grid.Column width={16}>
          <TypeDetailsCard {...t("types." + type, { returnObjects: true })} color={typeColor(type)} icon={type[0]} placeholder= { placeholder } subTypes={subTypes}/>
        </Grid.Column>
      </Grid.Row>)}
    </>
  )
})