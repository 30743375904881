import React, { Component } from "react";
import { Menu, Image, Container, Responsive, Sidebar, Icon } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import LandingItems from "./LandingItems";
import LoginItems from "./LoginItems";
import './style.css';
class NavBar extends Component {
  state = { showSidebar: false, width:0 }
  componentDidMount() {
    const language = localStorage.getItem("i18nextLng")
    if (language) this.setState({ language })
  }
  toggleSidebar = () => this.setState(prevState => { return { showSidebar: !prevState.showSidebar } })
  handleOnUpdate = (e, { width }) => this.setState({ width })
  render() {
    const { history, signout, user, i18n, lng } = this.props;
    

    return (
      <>
        <Menu style={{ backgroundColor: "white" }} secondary fixed="top" className="NavBar">
          <Container >
            <Menu.Item header as={NavLink} to="/" exact activeStyle={{ backgroundColor: "white" }} style={{ paddingLeft: 0, backgroundColor: "white" }}>
              <Image className="NavBarLogoImage" src='/assets/images/logo.png' style={{ width: 250 }} />
            </Menu.Item>
            <Responsive minWidth={Responsive.onlyComputer.minWidth} as={Menu.Menu} position="right" fireOnMount onUpdate={this.handleOnUpdate}>
              <LandingItems user={user} />
              <LoginItems user={user} history={history} signout={signout} />
            </Responsive>

            <Responsive maxWidth={Responsive.onlyTablet.maxWidth} as={Menu.Menu} position="right" >
              <Menu.Item onClick={this.toggleSidebar}>
                <Icon name="sidebar" />
              </Menu.Item>
            </Responsive>
          </Container>
        </Menu>
        {/* <Responsive minWidth={Responsive.onlyTablet.minWidth} fireOnMount onUpdate={this.handleOnUpdate}>
          {this.props.children}
        </Responsive>
        <Responsive {...Responsive.onlyMobile} as={Menu.Menu} position="right"> */}
          
{this.state.width < Responsive.onlyComputer.minWidth&&  <Sidebar as={Menu}  animation='overlay' 
  icon='labeled' 
  vertical 
  visible={this.state.showSidebar} 
  width='thin'direction="top" 
  className="SidebarMenu"
  fixed="top"
  >
              <LandingItems user = {user}/>
              <LoginItems user={user} history={history} signout={signout} vertical/>
              {/* <LanguageItems i18n={i18n} lng={lng} /> */}
            </Sidebar>}
            <Sidebar.Pushable>
            <Sidebar.Pusher
              dimmed={this.state.showSidebar && this.state.width < Responsive.onlyTablet.minWidth}
              onClick={() => {this.state.showSidebar && this.setState({ showSidebar: false })}}
              style={{ minHeight: "100vh" }}
            >
              {this.props.children}

            </Sidebar.Pusher>
          </Sidebar.Pushable>
        {/* </Responsive> */}
      </>
    );
  }
}



export default withNamespaces()(NavBar);