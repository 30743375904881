import React, { Component } from "react";
import { Grid, Loader, Image, Message, Icon, Segment,Header, GridColumn } from "semantic-ui-react";
import { connect } from "react-redux";
import { getResults, getUserDetails, resetTest } from "../../store/actions";
import ResultRadarChart from "../../components/SchoolReport/Sections/ResultRadarChart";
import { withNamespaces } from "react-i18next";
import { typeColor } from "../../helpers/typeColor";
import Corporation from "../../components/SchoolReport/Sections/Corporation";
import TypeCode from "../../components/SchoolReport/Sections/TypeCode";

import { printCorp } from "../../helpers/pdf_corporation";
import { print } from "../../helpers/pdf";
import GenderBarChart from "../../components/SchoolReport/Sections/GenderBarChart"
import ClassBarChart from "../../components/SchoolReport/Sections/ClassBarChart"
import AgeBarChart from "../../components/SchoolReport/Sections/AgeBarChart"
import "./style.css";
import ResultBarChart from "../../components/SchoolReport/Sections/ResultBarChart";
import TypeDescriptionCard from "../../components/SchoolReport/Sections/TypeDescriptions/TypeDescriptionCard";
import {getDivNamesAndCounts, getDivsAndUsers, getCorporationDetails, getCorporationResults, getCorporationUserResults} from "../../store/actions/corporation"
import UserList from "../../components/SchoolReport/Sections/UserList";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2pdf from 'html2pdf.js';
import { printWithZip } from "../../helpers/pdfWithZip";
import JSZip from 'jszip'
import Report from "../Report/Report";

class SchoolReport extends Component {
  state = {
    corporation:{},
  };
  async componentDidMount() {
    const { corporation_id,type,division_id } = this.props.match.params;
    let corporation={}
    let corporation_results
    if(type=="corporation"){
      corporation_results=await this.props.getCorporationResults(corporation_id,type)
      corporation=await this.props.getCorporationDetails(corporation_id);
      //await this.props.getCorporationUserResults(corporation_id,type)
    }
    else{
      let corporation_division=await this.props.getCorporationDetails(corporation_id);
      corporation["name"]=corporation_division.name
      corporation_division.divisions.forEach(division => {if(division.id==division_id){
        corporation["divisions_name"]=division.name
        corporation["user_count"]=division.users.length
      }})
      corporation_results=await this.props.getCorporationResults(division_id,type)
      //await this.props.getCorporationUserResults(division_id,type)
    }
    corporation["type"]=type
    //OKUL RAPORU SINIF ISIMLERI----------------------
    if(type=="corporation"){
      let new_divisions=await this.props.getDivNamesAndCounts(corporation_id);
      let divisions_name=""
      new_divisions=new_divisions.divisions
      new_divisions=new_divisions.sort(function(a,b){
        if(a.name > b.name) {return -1; }
        if(a.name < b.name) {return 1;}
        return 0; 
      } )
      new_divisions.forEach(div => {
        if(div.id=== new_divisions[new_divisions.length-1].id){
          divisions_name+=div.name
        }
        else{
          divisions_name+=div.name + ", "
        }
      });
      corporation['divisions_with_count']=new_divisions
      corporation['divisions_name']=divisions_name
    } 
    //----------------------------------------------------------------------
    corporation_results.averages.sort((a,b) => (a.score>=b.score) ? 1 :-1).reverse()
    let code=[]
    for(let i=0;i<6;i++){
      let el={}
       el["letter"]=corporation_results.averages[i].personality_type[0].toUpperCase()
       el["name"]=corporation_results.averages[i].personality_type
       code.push(el)
      }
    corporation['scores']=corporation_results.averages
    corporation['code']=code
    corporation['users']=corporation_results.list
    let age_list=[]
    let gender_list=[0,0]
    if(corporation && corporation.users){
      corporation.users.forEach((user,index)=>{
        let user_age_flag=0
        if(user.birthdate){
          let age=new Date().getFullYear()- (+user.birthdate.substring(0,4))
          age_list.map((key)=>{
            if(key.age===age){
              key.age_count=key.age_count+1
              user_age_flag=1
            }
          })
          if(!user_age_flag){
            age_list.push({"age":age,"age_count":1})
          }
        }
        if(user.code && user.code.code){
          user.code.code=user.code.code.split('')
        }
        if(user.gender){
          gender_list[0]=user.gender =="female" ? gender_list[0]+1 : gender_list[0]
          gender_list[1]=user.gender =="male" ? gender_list[1]+1 : gender_list[1]
        }
      })  
    }
    age_list.sort((a,b)=> a.age>=b.age ? 1:-1)
    corporation['age']=age_list
    corporation['gender']=gender_list
    this.setState({corporation:corporation})
  }
  async componentDidUpdate(prevProps, prevState) {
    if (!prevState.converting && this.state.converting) {
      const { user } = this.props;
      const corporation = this.state.corporation
      let id_list=["page-1"]
      let name=corporation.name
      if(corporation.type=="division"){
        name=corporation.divisions_name
      }
      if(this.state.print){
        await printCorp(
          this.state.mode,
          id_list,
          name
        )
      }
      
      /* if(this.state.printAll){
        var zip=new JSZip();
        printWithZip(
          zip,
          this.state.mode,
          ["report-page-1","report-page-2"],//,"page-1", "page-2"],
          "Pelin Kılıçdfgfdgdf"
        )
      } */
      
      this.setState({ converting: false ,print:false,printAll:false});
    }
  }
  print = (mode = { autoPrint: false }) => {
    console.log("PRINT ")
    this.setState({ mode, converting: true ,print:true});
  };
  printAll = (mode = { autoPrint: false }) => {
    this.setState({ mode, converting: true ,printAll:true});
  };
  columnCalculate(corporation){
    if(corporation.type=="corporation"){
      console.log("In SchoolReport, corporation.divisions_with_count ",corporation.divisions_with_count)
      if(corporation['divisions_with_count'].length>10){
        return 2;
      }
      else{
        return 3;
      }
    }
    else{
      return 2;
    } 
  }
  render() {
    const { resultsLoading,  t,   dsc } = this.props;
    const {  converting } = this.state;
    const code = (this.props.code || "").replace(",", "").split("");
    let corporation=this.state.corporation
    console.log("In SchoolReport, corporation->",corporation)
    
    if (resultsLoading) return <Loader active />;
    const placeholder = !corporation.scores;
    if (placeholder && this.props.self)
      return (
        <Grid container columns="1">
          <Grid.Column stretched>
          </Grid.Column>
        </Grid>
      );
      
    const data = (!corporation.scores ? [...new Array(6)] : corporation.scores)
      .map((result, index) => {
        return {
          name: !corporation.scores ? "" : t(`types.${result.personality_type}.name`),
          value: !corporation.scores ? 0 : result.score,
          color: !corporation.scores ? "#D8D8D8" : typeColor(result.personality_type),
          key: !corporation.scores ? index : result.personality_type,
          order: !corporation.scores
            ? index
            : t(`types.${result.personality_type}.order`)
        };
      })
      .sort((item1, item2) => item1.order - item2.order);

    if (this.state.image) return <Image src={this.state.image} />;
    const str = (
      <div id="meslekilgi-corporation-report">
        <Grid
          container
          stackable={!converting}
          className={"pt-2 ReportContainer" + (converting ? " Print" : "")}
          id="page-1"
        >
          {converting && <Header
            className="ReportHeader center"
            size="large"
            content="Meslekİlgi Sonuç Raporu"
          />}
          {!placeholder && <Grid.Row columns={placeholder ? 1 : 2}>
            <Grid.Column stretched>
              <Corporation
                corporation={corporation}
                print={this.print}
                loading={this.state.converting}
                showPrintButtons={!placeholder && !converting}
              />
              {!placeholder && <ResultRadarChart data={data} />}
            </Grid.Column>
            {!placeholder && (
              <Grid.Column stretched id="chart-code">
                {corporation.code && <TypeCode data={corporation.code.map((el)=>{return el.letter}).slice(0,3)} placeholder={placeholder} />
                  }
                <ResultBarChart data={data} placeholder={placeholder} />
              </Grid.Column>
            )}
          </Grid.Row>}
          {!placeholder && 
          <Grid.Row columns={ corporation.type=="corporation" ? 3 :2 } >
            { corporation.gender &&
             <Grid.Column stretched width={corporation.type=="corporation" ? (corporation.divisions_with_count.length>10 ? 4 : 5 ):6}>
              <GenderBarChart data={{
                data:corporation.gender,
                labels:["female","male"]
              }
              } placeholder="false"/>
            </Grid.Column>}
            {corporation.type=="corporation"  &&
            <Grid.Column stretched width={corporation.divisions_with_count.length>10 ? 8 : 5}>
              <ClassBarChart style={{padding:{left:15}}} data={corporation.divisions_with_count} placeholder="false"/>
            </Grid.Column>}
            <Grid.Column stretched width={corporation.type=="corporation" ?  (corporation.divisions_with_count.length>10 ? 4 : 6 ):10}>
              {corporation.age && (
                <AgeBarChart data={corporation.age
              } placeholder="false"/>
              )}
            </Grid.Column>
          </Grid.Row>
          }

          <Grid.Row>
          
          {!placeholder && corporation.code &&  (
            <>
              <TypeDescriptionCard  data={corporation.code[0].name} letter={corporation.code[0].letter}/>
              <TypeDescriptionCard  data={corporation.code[1].name} letter={corporation.code[1].letter}/>
              <TypeDescriptionCard  data={corporation.code[2].name} letter={corporation.code[2].letter}/>
              <></>
              <TypeDescriptionCard  data={corporation.code[3].name} letter={corporation.code[3].letter}/>
              <TypeDescriptionCard  data={corporation.code[4].name} letter={corporation.code[4].letter}/>
              <TypeDescriptionCard  data={corporation.code[5].name} letter={corporation.code[5].letter}/>
            </>
          ) }
          </Grid.Row> 
          </Grid>
          <Grid
            container
            stackable={!converting}
            className={"pt-2 ReportContainer" + (converting ? " Print" : "")}
            id="page-2"
          >
          {!placeholder && corporation.users && 
          <div>
          <Segment style={{display:"flex",height:"70px"}}>
    
          {/* <Header id="header" textAlign="left" floated= "left" className="ReportHeader left" style={{fontFamily:"Open Sans",fontWeight:900}} size="large">
             Katılımcı ÖğrencilerA
             </Header>
            {/* !placeholder && !converting && 
            <Header id="downloadAll" floated='right' icon="file pdf outline" content="Tümünü İndir"   style={{fontFamily:"Open Sans",fontWeight:900, textAlign:"right", justifyContent:"right"}}    onClick={ async  () => this.printAll()}/> */}
             

             <Header id="header" textAlign="left" floated= "left" className="ReportHeader left" style={{fontFamily:"Open Sans",fontWeight:900}} size="large">
             Katılımcı Öğrenciler
             </Header>
          </Segment>          
            { <UserList style={{pageBreakInside:"always",pageBreakBefore:"always"}} 
                      id="user_list"  
                      match={this.props.match} 
                      t={t} 
                      users={corporation.users} 
                      token={localStorage.getItem("token")} 
                      print={this.print}
                      loading={this.state.converting}
                      showPrintButtons={!placeholder && !converting}
                      mode={this.state.mode}/>  }
            
            </div>
          }
          
        </Grid>
      {/* !placeholder&&  corporation.users && 
        corporation.users.map((user,index) => {
          console.log(user.name)
          console.log(user.id)
          return (
            <Report id={"user-report"+user.id} user_id={user.id} hidden
          style={{display:"none", visibility:"hidden"}}></Report>
          )
        }) */
      }
     
      </div>
      

    );
    return str;
  }
}

const mapStateToProps = state => {
  return {
    dsc: state.results.dsc,
    user: state.results.user,
    resultsLoading: state.loading["GET_CORPORATION_RESULTS"],
    self:
      state.results.scores[0] &&
      state.results.scores[0].user_id === state.auth.user.id
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getDivNamesAndCounts: corporation_id => dispatch(getDivNamesAndCounts(corporation_id)),
    getCorporationDetails:corporation_id => dispatch(getCorporationDetails(corporation_id)),
    getDivsAndUsers: () => dispatch(getDivsAndUsers),
    getCorporationResults: (corporation_id,type) => dispatch(getCorporationResults(corporation_id,type)),
    getCorporationUserResults: (corporation_id,type) => dispatch(getCorporationUserResults(corporation_id,type)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(SchoolReport));
