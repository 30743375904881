import React, { Component } from "react";
import { Grid, Loader, Image, Message, Icon, Header } from "semantic-ui-react";
import { connect } from "react-redux";
import { getResults, getUserDetails, resetTest } from "../../store/actions";
import ResultRadarChart from "../../components/Report/Sections/ResultRadarChart";
import { withNamespaces } from "react-i18next";
import { typeColor } from "../../helpers/typeColor";
import Profile from "../../components/Report/Sections/Profile";
import TypeCode from "../../components/Report/Sections/TypeCode";
import Programs from "../../components/Report/Sections/Programs";
import TypeDetails from "../../components/Report/Sections/TypeDetails";
import { printWithZip } from "../../helpers/pdfWithZip"
import { print } from "../../helpers/pdf"
import "./style.css";
import ResultBarChart from "../../components/Report/Sections/ResultBarChart";
import FillAgain from "../../components/Report/Sections/FillAgain";
import JSZip from 'jszip'
import { saveAs } from 'file-saver';




const codeTypes = {
  S: "social",
  C: "conventional",
  E: "enterprising",
  I: "investigative",
  R: "realistic",
  A: "artistic"
};

class Report extends Component {
  state = {
    resetRejected: false,
    resetLoading: false
  };
  componentDidMount() {
    //const { user_id } = this.props.match ? this.props.match.params : 0;
    let user_id;
    if (this.props.match) {
      user_id = this.props.match.params.user_id

    }
    if (this.props.user_id) {
      user_id = this.props.user_id
    }
    this.props.getResults(user_id);
    this.props.getUserDetails();
    // else this.props.getResults(user_id,token);
    if (window.name == user_id.toString()) {
      setTimeout(() => {
        const { user } = this.props;
        print(
          this.state.mode,
          ["report-page-1", "report-page-2"],
          `${user.first_name} ${user.last_name}`
        ).then(_ => this.setState({ converting: false }))
      }, 1000)
      setTimeout(() => {
        window.close()
      }, 5000)
    }
    /*if(window.name=="Corporation"+user_id.toString() ){
      var zip = new JSZip();
      setTimeout(() =>   {
      const { user } = this.props;      
      printWithZip(
        zip,
        this.state.mode,
        ["page-1", "page-2"],
        `${user.first_name} ${user.last_name}`
      ).then(_ => this.setState({ converting: false })) }, 20000)
      setTimeout(() => {
        zip.generateAsync({type:"blob"})
        .then(function(content) {
            // see FileSaver.js
            saveAs(content, "Meslekİlgi.zip");
        });
        window.close()
      },55000)
      
    } */


  }
  componentDidUpdate(prevProps, prevState) {
    if (!prevState.converting && this.state.converting) {
      const { user } = this.props;
      print(
        this.state.mode,
        ["report-page-1", "report-page-2"],
        `${user.first_name} ${user.last_name}`
      ).then(_ => this.setState({ converting: false }));
    }
  }
  print = (mode = { autoPrint: false }) => {
    this.setState({ mode, converting: true });
  };
  goToProfession = () => this.props.history.push("/profession");
  resetTest = reset => {
    this.setState({resetLoading:true});
    if (reset)
      this.props.reset().then(response => {
        this.setState({resetLoading:false});
        if (response) this.props.history.push("/main");
      });
    else this.setState({ resetRejected: true, resetLoading:false });
  };

  generateDynamicResult = (code) => {
    const { converting } = this.state;
    const {programs, scores, dsc, t}  = this.props;

    const subTypes = code
    .map(codeChar => codeTypes[codeChar])
    .filter(subType => !dsc.some(type => type === subType));

    const data = (!scores[0] ? [...new Array(6)] : scores)
    .map((result, index) => {
      return {
        name: !scores[0] ? "" : t(`types.${result.personality_type}.name`),
        value: !scores[0] ? 0 : result.score,
        color: !scores[0] ? "#D8D8D8" : typeColor(result.personality_type),
        key: !scores[0] ? index : result.personality_type,
        order: !scores[0]
          ? index
          : t(`types.${result.personality_type}.order`)
      };
    })
    .sort((item1, item2) => item1.order - item2.order);


    return <div id={"report"}>
      <Grid
        container
        stackable={!converting}
        className={"pt-2 ReportContainer" + (converting ? " Print" : "")}
        id="report-page-1"
      >
        {converting && <Header
          className="ReportHeader center"
          size="large"
          content="Meslekİlgi Sonuç Raporu"
        />}
        <Grid.Row columns={2}>
          <Grid.Column stretched>
            <Profile
              user={this.props.user}
              print={this.print}
              loading={converting}
              showPrintButtons={!converting}
            />
            {<ResultRadarChart data={data} />}
          </Grid.Column>

          <Grid.Column stretched id="chart-code">
            <TypeCode data={code} placeholder={false} />

            <ResultBarChart data={data} placeholder={!scores[0]} />
          </Grid.Column>

        </Grid.Row>

        <TypeDetails types={dsc} placeholder={false} />
        <TypeDetails
          types={subTypes}
          placeholder={false}
          subTypes
        />


      </Grid>

      <Grid
        className={"ReportContainer" + (converting ? " Print" : "")}
        id="report-page-2"
        container
      >
        {programs.length > 2 && (
          <Grid.Row columns="one" className="pt-2">
            <Grid.Column>{<Programs open={converting} />}</Grid.Column>
          </Grid.Row>
        )}
      </Grid>

    </div>
  }

  generateTestResetter = (code)=>{
    const {scores, programs, count, self, user} = this.props;
    // TODO: admin sonuçlara bakarken eğer öğrencinin test sonucu yoksa server'dan error dönüyor. o data içinde normalde öğrencinin adı-soyadı da yazması lazım. sonuç yoksa error değil en azından user'ın adı-soyadını return etmeli server. Yoksa ad-soyad boş çıkıyor.
    const scoresExists = scores[0];
    const adminViewingTheReport = !self; // bu raporu açan kişi kullanıcının kendisi değil de okul yöneticisi vs. olabilir. Bunlar rest butonuna basamamalı. Sadece öğrencinin kendisi basabilmeli.
    const date = scores[0] && scores[0].created_at;
    const isUserNull = user && user.first_name==="" && user.last_name ==="" && Object.keys(user).length===2;
    return (
      <Grid container columns="1" id="page-1">
        <Grid.Column stretched>
          <Profile user={isUserNull ? null : user} />
          {!isUserNull && <FillAgain onFillAgain={this.resetTest} code={code} date={date} jobCount={programs.length} codeCount={count} scoresExists={scoresExists} adminViewingTheReport={adminViewingTheReport} />}
        </Grid.Column>
      </Grid>
    );
  }



  render() {
    const { resultsLoading, scores, programs } = this.props;
    console.log(`this.props: `, this.props)
    const { converting, resetRejected, resetLoading } = this.state;
    const code = (this.props.code || "").replace(",", "").split("");
    const placeholder = !scores[0] || programs.length < 3;


    if (resultsLoading || resetLoading) return <Loader active />;
    if (placeholder &&  !resetRejected)   return this.generateTestResetter(code);
    if (this.state.image) return <Image src={this.state.image} />;
    else return this.generateDynamicResult(code);

  }
}

const mapStateToProps = state => {
  return {
    scores: state.results.scores,// ||  state.results.corp_user_results[0].scores,
    code: state.results.code,//|| state.results.corp_user_results[0].code.code,
    dsc: state.results.dsc,//|| state.results.corp_user_results[0].code.dsc,
    programs: state.results.programs,// || state.results.corp_user_results[0].jobs,
    user: state.results.user,// || state.results.corp_user_results[0].user,
    count: state.results.count,//|| state.results.corp_user_results[0].count,
    resultsLoading: state.loading["GET_RESULTS"],
    self:
      state.results.scores[0] &&
      state.results.scores[0].user_id === state.auth.user.id
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getResults: user_id => dispatch(getResults(user_id)),
    getUserDetails: () => dispatch(getUserDetails()),
    reset: () => dispatch(resetTest())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(Report));
