import React from "react";
import { Message, Grid, Button, Segment, Icon } from "semantic-ui-react";
import moment from "moment";


const limitedSuggestionHeader = "Mesleki İlginiz Ayrışmadı!";
const limitedSuggestionText = <p>
  Meslekİlgi Envanteri sonucunuz doğrultusunda, Meslekİlgi Modülü
  size sınırlı sayıda lisans programı önerebilmektedir. Bu
  sıklıkla rastlanılan bir durumdur. Lütfen kişilik tipinize
  ilişkin açıklamaları dikkatlice okuyunuz. Hayalinizdeki
  meslekler ve bu mesleklerin hangi üniversitelerde lisans
  programı olduğu hakkında bilgi edininiz. Ayrıca, okul rehber
  öğretmeniniz ile görüşerek kişilik tipinize uygun olabilecek
  meslekler ve ilgilerinizi nasıl geliştirebileceğiniz konusunda
  bilgi alınız. Meslekİlgi envanterini Rehber Öğretmenizin önerisi
  doğrultusunda en az 3 hafta sonra tekrar cevaplayıp,
  sonuçlarınızı Rehber Öğretmeninizle değerlendiriniz.
</p>;



const interestsNotSeperatedHeader = "Mesleki İlginiz Ayrışmadı!";
const interestsNotSeperatedText = <p>
  <p>
    Meslek İlgi envanterinden elde edilen sonuçlar mesleki
    ilgilerinizin ayrışmadığını göstermiştir. Mesleki ilgilerin
    ayrışmaması, bireylerin bir çok farklı meslek alanına benzer
    derecede ilgi duymaları ve bir meslek alanına yönelik
    ilgilerinin diğer alanlardan belirgin ölçüde farklılaşmaması
    şeklinde açıklanmaktadır.
  </p>
  <p>
    Bireylerin mesleki ilgilerinin ayrışmaması sık karşılaşılan
    bir durumdur. Bunun nedeni bazen bir çok mesleğe yüksek
    düzeyde ilgi duymalarıdır. Bazen de farklı bir çok meslek
    alanına yönelik ilgi düzeylerinin düşük olmasıdır. Bu aşamada
    anketi bir kez daha tekrarlayabilirsiniz.
  </p>
</p>


const personalityCanNotCalculatedHeader = <span>
  Kişilik tipiniz hesaplanamadığı için rapor
  gösterilemiyor.{" "}
  <b>Lütfen rehber öğretmeninizle iletişime geçiniz.</b>
</span>

const personalityCanNotCalculatedText = <p style={{ textAlign: "justify" }}>
  <br />
  Meslekİlgi Envanteri sonucunuz doğrultusunda,
  birbiri ile tutarlı olabilecek kişilik tipi kodları elde edilememiştir.
  Bu nedenle de, Meslekİlgi Modülü size lisans programları önerememektedir.
  Bu rastlanılan bir durumdur. Hayalinizdeki meslekler ve bu mesleklerin
  hangi üniversitelerde lisans programı olduğu hakkında
  <a href="https://meslekilgi.com/search-program"
    style={{ color: "black", fontWeight: "bold", textDecoration: "underline" }}>
    Meslekİlgi Modülü Meslek Arama Motorundan
  </a>
  {" "}bilgi edininiz. Ayrıca, okul rehber öğretmeniniz ile görüşerek
  kişilik tipinize uygun olabilecek meslekler ve ilgilerinizi nasıl
  geliştirebileceğiniz konusunda bilgi alınız. Meslekİlgi envanterini
  Rehber Öğretmenizin önerisi doğrultusunda <u>en az 3 hafta sonra </u> tekrar
  cevaplayıp, sonuçlarınızı Rehber Öğretmeninizle değerlendiriniz.


  {/* Meslekİlgi Envanteri profilinizi Okul Rehber
öğretmeninizle paylaşınız ve onunla birlikte
değerlendiriniz. Mesleki ilgilerinizi belirlemede ne
gibi ilave etkinliklerin ve bilgilerin size yardımcı
olabileceği konusunda, Okul Rehber öğretmeninize
danışınız. Anketi Rehber öğretmeninizle görüşerek, ileri
bir tarihte tekrar doldurabilirsiniz. */}
</p>


const resultNotFoundHeader = <span>Test Sonucu Bulunamadı!</span>


const FillAgain = ({ onFillAgain, code, date, codeCount, scoresExists, adminViewingTheReport }) => {
  const ac_condition =
    code &&
    code[0] &&
    code[1] &&
    ((code[0] === "A" && code[1] === "C") || (code[1] === "A" && code[0] === "C"));




  let headerComponent = null;
  let explanationComponent = null;

  if (codeCount < 2 && ac_condition) {
    headerComponent = limitedSuggestionHeader;
    explanationComponent = limitedSuggestionText;
  }
  else if (codeCount < 2 && !ac_condition) {
    headerComponent = interestsNotSeperatedHeader;
    explanationComponent = interestsNotSeperatedText;
  }
  else if (codeCount >= 2 && scoresExists) {
    headerComponent = personalityCanNotCalculatedHeader;
    explanationComponent = personalityCanNotCalculatedText;
  }
  else
    headerComponent = resultNotFoundHeader

  return (
    <Segment padded>
      <Grid container stackable className="p-2">
        <Grid.Row columns="1">
          <Grid.Column>
            <Message warning icon>
              <Icon name="info circle" className="report-icon" />
              <Message.Content>
                <Message.Header>{headerComponent}</Message.Header>
              </Message.Content>
            </Message>
            {explanationComponent}
          </Grid.Column>
        </Grid.Row>
        {!adminViewingTheReport &&
          <Grid.Row>
            <Grid.Column>
              <Button color="teal" onClick={() => {
                const remainingDaysForReset = 21 - (moment().diff(date, "days"));

                if (remainingDaysForReset > 0)
                  alert("Anketi " + remainingDaysForReset + " gün sonra tekrar doldurabilirsiniz...");
                else if (adminViewingTheReport)
                  alert("Bu butona sadece testi çözen kişi basabilir.");
                else
                  onFillAgain(true);

              }}>
                Anketi tekrar doldurmak istiyorum.
              </Button>
              <Button
                color="grey"
                className="mt-1"
                onClick={() => {
                  if (adminViewingTheReport)
                    alert("Bu butona sadece testi çözen kişi basabilir.");
                  else
                    onFillAgain(false)
                }}
              >
                Anketi tekrar doldurmak istemiyorum.
              </Button>
            </Grid.Column>
          </Grid.Row>
        }
      </Grid>
    </Segment>
  );
};

export default FillAgain;
