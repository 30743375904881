import React, { Component } from "react";
import { connect } from "react-redux";
import { Image, Segment, Grid, Menu, Header } from "semantic-ui-react";
import { checkout } from "../../store/actions";
import Product from "./Product";
import School from "./School";
import { withNamespaces } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

class Purchase extends Component {
  state = {
    activeItem: "personal",
    open: false,
  };

  checkout = (userCapacity, appliedCoupon = null) =>
    this.props
      .checkout(this.props.test.id, +userCapacity, appliedCoupon)
      .then((data) => {
        if (data && data.redirect_url) {
          window.location.replace(data.redirect_url);

          //this.setState({ open: true });
        }
      });
  handleItemClick = (_, { name }) => {
    this.setState({ activeItem: name });
  };

  handleClose = () => {
    this.setState({ open: false });
    window.location.replace("/");
  };

  render() {
    const { t } = this.props;
    return (
      <Segment style={{ maxWidth: 1024, margin: "auto" }}>
        <Grid centered>
          <Grid.Column
            computer="6"
            tablet="6"
            style={{ paddingLeft: 0, paddingTop: "2em" }}
            only="computer tablet"
            verticalAlign="middle"
          >
            <Image src="/assets/images/purchase.png" />
          </Grid.Column>
          <Grid.Column
            computer="10"
            tablet="10"
            mobile="14"
            style={{ padding: "4em" }}
          >
            <Header style={{ color: "#3C5994" }} size="large">
              <Header.Content>
                <Image src="/assets/images/logo.png" />
              </Header.Content>
            </Header>
            <Menu tabular>
              <Menu.Item
                name={t("purchase.personal")}
                //name="personal"
                color="teal"
                active={this.state.activeItem === "personal"}
                onClick={() => this.setState({ activeItem: "personal" })}
              />
              <Menu.Item
                name={t("purchase.school")}
                color="teal"
                active={this.state.activeItem === "school"}
                onClick={() => this.setState({ activeItem: "school" })}
              />
            </Menu>
            {this.state.activeItem === "personal" ? (
              <Product
                checkout={this.checkout}
                product={this.props.test.products[4]}
              />
            ) : (
              <School
                checkout={this.checkout}
                product={this.props.test.products.slice(0, 4)}
              />
            )}
          </Grid.Column>
        </Grid>
        {/* <PurchaseSuccess open={this.state.open} /> */}

        <Dialog
          open={false}//{this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Satın Alma"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {"Satın Alma işlemi başarıyla gerçekleşmiştir"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              Tamam
            </Button>
          </DialogActions>
        </Dialog>


      </Segment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    test: state.tests[0],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkout: (testId, userCapacity, appliedCoupon) =>
      dispatch(checkout(testId, userCapacity, appliedCoupon)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(Purchase));
