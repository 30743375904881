import React, { Component } from "react";
import {
  Segment,
  Header,
  Table,
  Icon,
  Accordion,
  List,
  Grid,
  Message,
  Button,
  Image
} from "semantic-ui-react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { programLink, jobImageLink } from "../../../config/environment";
import capitalize from "../../../helpers/capitalize";
import Profession from "../../../containers/Profession";
import jobDescriptions from "../../../assets/jobDescriptions";



const mainHeader =  <Header
className="ReportHeader"
size="large"
content="Kişilik Tipinize Uygun Olabilecek Mesleklerin Lisans Programları"
/>;

class Programs extends Component {
  state = {
    activeIndex: -1,
    data: [],
    showProfession: false
  };

  toggleAccordion = (e, { index }) => {
    this.setState(prevState => ({
      activeIndex: prevState.activeIndex === index ? -1 : index
    }));
  };
  selectProgram = (e, { index }) => {
    this.setState(prevState => ({
      data: prevState.data.map((program, i) => {
        if (index === i)
          return {
            ...program,
            selected: !program.selected
          };
        return program;
      })
    }));
  };

  render() {
    const { activeIndex } = this.state;
    const data = this.props.programs;
    const { open } = this.props;

    return (
      <Segment
        padded
        className="pageBreak p-4"
        loading={data.length > 5 && this.props.submitJobsLoading}
      >
        {mainHeader}
        {!this.state.showProfession && data.length >= 5 ? (
          <p className="pb-2">
            Meslekİlgi Modülü'nde tamamladığınız aşamalar sonucunda, kişilik
            tipinize uygun olabilecek mesleklerin lisans programlarının isimleri ve açıklamaları
            aşağıda verilmektedir.{" "}
            {!open && (
              <span>
                Lisans programları; <strong>YÖK ATLAS</strong>’ın tercih puanına
                göre ilk 5 program olacak şekilde listelenmektedir. Tüm lisans programlarını görmek için “
                <strong>Tümünü Gör</strong>” butonuna tıklayarak YÖK sitesini
                ziyaret edebilirsiniz.
              </span>
            )}
           
          </p>
        ) : (
          <p className="pb-2">
            Meslekİlgi Envanteri sonucunuz doğrultusunda, Meslekİlgi Modülü size
            sınırlı sayıda lisans programı önerebilmektedir. Bu sıklıkla
            rastlanılan bir durumdur. Lütfen kişilik tipinize ilişkin
            açıklamaları dikkatlice okuyunuz. Hayalinizdeki meslekler ve bu
            mesleklerin hangi üniversitelerde lisans programı olduğu hakkında{" "}
            <a href="https://meslekilgi.com/search-program" style={{ color: "black", fontWeight: "bold",textDecoration: "underline" }}>Meslekİlgi Modülü Meslek Arama Motorundan</a>{" "}bilgi edininiz. Ayrıca, okul rehber öğretmeniniz ile görüşerek
            kişilik tipinize uygun olabilecek meslekler ve ilgilerinizi nasıl
            geliştirebileceğiniz konusunda bilgi alınız. Meslekİlgi envanterini
            Rehber Öğretmenizin önerisi doğrultusunda <u>en az 3 hafta sonra </u> tekrar
            cevaplayıp, sonuçlarınızı Rehber Öğretmeninizle değerlendiriniz.
          </p>
        )}
        {this.state.showProfession && data.length > 5 ? (
          <Profession />
        ) : (
          <Table celled padded selectable unstackable>
            {/* <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="center" collapsing><Icon name="star" size="large" color="grey" disabled /></Table.HeaderCell>
                <Table.HeaderCell>
                  <Header
                    content="Meslek"
                    size="large"
                    className="ReportHeader ReportHeaderLight"
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header> */}
            <Accordion as={Table.Body}>
              {data.map((item, index) => {
                const jobDescription = jobDescriptions.find(
                  jd => jd.yokatlas === item.yokatlas
                );

                if(!jobDescription)
                return null;

                const isThisForeignLanguageGroup = ["88888", "99999"].includes(item.yokatlas);
                return (
                  <Table.Row key={index}>
                    {/* <Table.Cell textAlign="center" >
                <Icon name="star"
                  size="large"
                  style={{ cursor: "pointer", color: "#ccc" }}
                  {...item.selected ? { color: "yellow" } : {}}
                  onClick={this.selectProgram}
                  index={index} />
              </Table.Cell> */}
                    <Table.Cell>
                      <Accordion.Title
                        active={activeIndex === index || open}
                        onClick={this.toggleAccordion}
                        index={index}
                      >
                        <Grid columns={2}>
                          <Grid.Column>
                            <Header
                              floated="left"
                              size="medium"
                              style={{ fontFamily: "Open Sans" }}
                            >
                              {/* {activeIndex === index  &&!this.props.open? "Lisans Programları": capitalize(item.name)} */}
                              {item.name}
                            </Header>
                          </Grid.Column>
                          {!open && (
                            <Grid.Column>
                              <Header
                                floated="right"
                                size="small"
                                color="grey"
                                style={{ marginRight: 0 }}
                              >
                                Keşfet
                                <Icon
                                  name={
                                    activeIndex === index
                                      ? "angle up"
                                      : "angle down"
                                  }
                                  floated="right"
                                  style={{ marginRight: 0, textAlign: "right" }}
                                />
                              </Header>
                            </Grid.Column>
                          )}
                        </Grid>
                        {item.bundle.length > 1 && (
                          <p className="pb-2">
                            <span
                              style={{
                                fontWeight: "bold",
                                textDecoration: "underline"
                              }}
                            >
                              {isThisForeignLanguageGroup? "Lisans program isimleri: "
                                : "Alternatif lisans program isimleri: "}
                            </span>
                            {item.bundle
                              .filter(t => t[1] !== item.name)
                              .map(t => t[1])
                              .join(", ")}
                          </p>
                        )}
                      </Accordion.Title>
                      <Accordion.Content
                        active={activeIndex === index || this.props.open}
                      >
                        <Grid style={{ color: "#7f7f7f" }}>
                          <Grid.Column>
                            <div>
                              {!open && (
                                <Image
                                  bordered
                                  floated="left"
                                  size="medium"
                                  src={`${jobImageLink}/${item.yokatlas}.png`}
                                />
                              )}
                              {!isThisForeignLanguageGroup && !open && (
                                <Header style={{ color: "#36495D" }}>
                                  {item.name}{" "}
                                  <span style={{ fontWeight: "normal" }}>
                                    Bölüm Tanımı
                                  </span>
                                </Header>
                              )}
                              <p>{jobDescription.desc}</p>
                              {!isThisForeignLanguageGroup && !open && (
                                <Header style={{ color: "#36495D" }}>
                                  {item.name}{" "}
                                  <span style={{ fontWeight: "normal" }}>
                                    Bölümü Çalışma Alanları
                                  </span>
                                </Header>
                              )}
                              {!isThisForeignLanguageGroup && !open && (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: jobDescription.fields
                                  }}
                                />
                              )}
                            </div>
                          </Grid.Column>
                        </Grid>

                        {!open && (
                          <List divided relaxed>
                            {item.universities.map((school, i) => (
                              <List.Item key={i}>
                                <List.Content className="py-1">
                                  <span>{capitalize(school.name)}</span>
                                  {!open && (
                                    <a
                                      style={{ float: "right" }}
                                      href={school.url}
                                      rel="noopener noreferrer"
                                      target="_blank"
                                    >
                                      <Icon name="external alternate" />
                                      Detaylar
                                    </a>
                                  )}
                                </List.Content>
                              </List.Item>
                            ))}
                            {!open &&
                              item.bundle.map((b, i) => (
                                <List.Item key={`bundle_${i}`} className="py-1">
                                  <List.Content>
                                    <a
                                      style={{
                                        float: "right",
                                        fontWeight: "bolder"
                                      }}
                                      href={programLink + b[0]}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <Icon name="external alternate" />
                                      Tüm "{b[1]}" Lisans Programlarını Gör{" "}
                                    </a>
                                  </List.Content>
                                </List.Item>
                              ))}
                          </List>
                        )}
                      </Accordion.Content>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Accordion>
          </Table>
        )}
        <Message
          color="red"
          size="massive"
          hidden={
            data.length < 6 ||
            this.state.showProfession ||
            ![11, 12].includes(this.props.grade)
          }
        >
          <Message.Content>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <div>
                <Icon name="question circle" size="big" />
                Meslekleri karşılaştırarak seçim yapmak ister misiniz?
              </div>
              <Button
                inverted
                color="red"
                onClick={() => this.setState({ showProfession: true })}
              >
                Evet
              </Button>
            </div>
          </Message.Content>
        </Message>
      </Segment>
    );
  }
}
const mapStateToProps = state => ({
  programs: state.results.programs,
  submitJobsLoading: state.loading["SUBMIT_JOBS"],
  grade: +state.auth.details.grade,
  isStudent: state.auth.details.isStudent === "true"
});

export default connect(mapStateToProps)(withNamespaces()(Programs));
