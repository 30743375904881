import React from 'react';
import {Grid, Segment, Image} from 'semantic-ui-react';
import LoadingModal from '../../components/ui/LoadingModal/LoadingModal';
import './style.css';

export default props => (
  <>
    <LoadingModal show = {props.loading}/>
    <Grid textAlign="center" verticalAlign="middle" columns={1} className="LoginWrapper" stackable>
      <Grid.Column only="computer">
        <Segment  style={{maxWidth: 1280, margin: "auto", backgroundColor: "#F6F7F9"}}>
          <Grid columns={2} divided stackable>
            <Grid.Column style={{minHeight: '45em', maxHeight: '55em'}}>
              {props.children}
            </Grid.Column>
            <Grid.Column style={{background: 'white', padding: '4em',  paddingBottom: '0px',overflow:"hidden" }} >
              <Image src='/assets/images/logo.png'/>
              <div style={{textAlign: 'left', fontSize: '1.4em', lineHeight: '1.4em'}}>
                <b>{props.t('login.inventory')}</b>
                {props.t('login.desc')}
              </div>
              <Image src='/assets/images/headimg.png' style={{marginBottom:"-6em"}} centered/>
            </Grid.Column>
          </Grid>
        </Segment>
      </Grid.Column>
      <Grid.Column only="tablet mobile">
        <Segment style={{maxWidth: 1280, margin: "auto", backgroundColor: "#F6F7F9"}}>
          <Grid columns={1} divided stackable>
            <Grid.Column style={{minHeight: '45em', maxHeight: '55em'}}>
              {props.children}
            </Grid.Column>
          </Grid>
        </Segment>
      </Grid.Column>
    </Grid>
  </>
);
