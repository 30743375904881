import React, { Component } from 'react';
import { Menu, Button } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { getTest, getUserDetails } from '../../store/actions';
import { connect } from 'react-redux';


const leftBorderedStyle= {
  borderLeft :".5px solid black",
  borderRadius:0,
  paddingLeft:"2em"
}
class LoginItems extends Component{
  componentDidMount(){
    if(!this.props.test){
      this.props.getTest();
      this.props.getUserDetails();
    }
  }

  render(){
    const { t, test, user, vertical, signout } = this.props;

    let userLink = "/";

    if (test && !test.is_purchased) {
      userLink = "/purchase"
    } else if (test && test.is_completed) {
      userLink = "/report/" + user.id;
    } else if (test && !test.is_completed) {
      userLink = "/test"
    }

    return (
      user ?
        <>
          <Menu.Item>
            <NavLink style={vertical ? {color:"black"} : leftBorderedStyle} to={userLink}> <span>{user.first_name}</span></NavLink>
          </Menu.Item>
          <Menu.Item name="logout"><Button onClick={signout} >{t("login.logout")} </Button></Menu.Item>
        </>
        :
        <>
          <Menu.Item header name="login" as={NavLink} to={"/login"} style={vertical ? {} : leftBorderedStyle} content={t("login.login")} />
          <Menu.Item>
            <NavLink to="/signup" >
              <Button color="teal">{t('login.sign_up')}</Button>
            </NavLink>
          </Menu.Item>
        </>)
  }
}

const mapStateToProps = state => {
  return {
      testLoading:state.loading["GET_TEST"],
      test:state.tests[0],
      user:state.auth.user
  }
}
const mapDispatchToProps = dispatch => {
  return {
      getTest : () => dispatch(getTest()),
      getUserDetails: () => dispatch(getUserDetails())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withNamespaces()(LoginItems));
