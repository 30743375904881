import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon, Input } from "semantic-ui-react";
import { withNamespaces } from "react-i18next";
import { validate_coupon } from "../../store/actions";

class Product extends Component {
  state = {
    appliedCoupon: "",
    coupon: "",
    isPercent: false,
    percent: null,
    value: null,
    price: this.props.product.price,
    couponed_price: null,
  };
  handleInput = (e) => this.setState({ coupon: e.target.value.trim()});
  applyCode = (code) => {
    if (code.trim().length === 0) {
      alert("İndirim kupon kodunuz varsa sağdaki alana bu kodu yazdıkran sonra 'Kupon Uygula' butonuna basınız.");
      return;
    }
    this.props.validate_coupon(code).then((data) => {
      if (data && data.coupon) {
        let couponed_price =
          this.props.product.price -
            (this.props.product.price * data.coupon.value) / 100 <
            0
            ? 0
            : Math.round(
              (this.props.product.price -
                (this.props.product.price * data.coupon.value) / 100) *
              100
            ) / 100;
        data.coupon.percent
          ? this.setState({
            appliedCoupon: this.state.coupon,
            isPercent: true,
            percent: data.coupon.value,
            couponed_price,
          })
          : this.setState({
            appliedCoupon: this.state.coupon,
            isPercent: false,
            value: data.coupon.value,
            couponed_price:
              data.coupon.value > this.props.product.price
                ? 0
                : this.props.product.price - data.coupon.value,
          });
      } else {
        alert("Kupon Bulunamadı.");
      }
    });
  };
  render() {
    const { appliedCoupon } = this.state;
    return (
      <>
        <p>
          Ödeme yöntemi olarak <b>sadece kredi kartı</b> ya da{" "}
          <b>banka kartı</b> ile ödeme alınmaktadır. Sanal pos 128 bit güvenli
          sertifika ile şifrelenmektedir ve kart bilgileriniz saklanmamaktadır.
          “Satın Al” butonuna basarak ödeme sayfasına gidebilirsiniz.
        </p>

        <div style={{marginBottom:"10px"}} >
          <Button icon color="teal" labelPosition="left"
            onClick={() => this.props.checkout(1, appliedCoupon)}
            style={{ width: "180px" }}
          >
            <Icon name="cart" />
            Satın Al
          </Button>


          <Button basic color="grey">
          {/* <del style={{ color: "#FF0000" , marginRight:"7px" }}> {150} TL</del> */}
            {appliedCoupon && <del style={{ color: "#FF0000" , marginRight:"7px" }}> {this.state.price} TL</del>}
            <b>{appliedCoupon ? this.state.couponed_price : this.state.price} TL</b>
          </Button>
        </div>



        <div>
          <Button
            icon
            color="teal"
            labelPosition="left"
            onClick={() => this.applyCode(this.state.coupon)}
            style={{ width: "180px" }}
          >
            <Icon name="money bill alternate outline" />
            Kupon Uygula
          </Button>

          <Input
            //labelPosition="right"
            //type="tel"
            value={this.state.coupon}
            placeholder="İndirim Kuponu Kodu"
            onChange={this.handleInput}
          />
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  validate_coupon: (code) => dispatch(validate_coupon(code)),
});

export default connect(null, mapDispatchToProps)(withNamespaces()(Product));

// export default (props) => (
//   <>
//     <p>
//       Ödeme yöntemi olarak <b>sadece kredi kartı</b> ya da <b>banka kartı</b>{" "}
//       ile ödeme alınmaktadır. Sanal pos 128 bit güvenli sertifika ile
//       şifrelenmektedir ve kart bilgileriniz saklanmamaktadır. “Satın Al”
//       butonuna basarak ödeme sayfasına gidebilirsiniz.
//     </p>
//     <Button.Group onClick={() => props.checkout(1)}>
//       <Button icon color="teal" labelPosition="left">
//         <Icon name="cart" />
//         Satın Al
//       </Button>
//       <Button basic color="grey">
//         {/* <strike style={{color:'#FF0000'}}>₺{props.product.price + 30}</strike> */}

//         <b>₺{props.product.price}</b>
//       </Button>
//     </Button.Group>
//     <div>
//       <Button icon color="teal" labelPosition="left">
//         Uygula
//       </Button>
//       <Input
//         label={{ basic: true }}
//         labelPosition="right"
//         type="tel"
//         value={this.state.coupon}
//         placeholder="Kupon Kodu"
//         onChange={this.handleInput}
//       />
//     </div>
//   </>
// );
