import React from "react";
import "./LoadingModal.css";
import { Dimmer, Loader } from "semantic-ui-react";
import {withNamespaces} from 'react-i18next';

export default withNamespaces()(({show, t}) => show ?
  <Dimmer active inverted>
    <Loader size="large">{t('loading')}</Loader>
  </Dimmer>
  : null)
