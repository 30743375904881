import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Button, Icon, Image} from 'semantic-ui-react';
import { links } from '../../config/constants';

export default withNamespaces()(({ t }) => (
  <>
  <div className="rounded-box mb-4 info-cards info-signup">
    <div style={{padding:"1.5em"}}>
    <span className="mb-1 info-title info-text">
        İlgilerinize yönelik <b>mesleklerin neler olduğunu</b> biliyor musunuz?
    </span>
    <div>
      <Button className="rounded-box mt-1" color="teal">{t('login.sign_up')}</Button>
    </div>
    </div>
  </div>
  <div className="rounded-box mb-4 info-cards">
  <div style={{padding:"1.5em"}}>
    <div style={{ fontSize: '1.5em', lineHeight: '1.5em', fontWeight: 'bold', marginBottom: '0.5em' }}>
      {t('info.contact')}
    </div>
    <div className="info-phone info-text" >
      <Icon name="phone" flipped="horizontally" color='teal'  />
      <span>{t('info.phone')}</span>
    </div>
    <div className="info-mail info-text" > 
      <Icon name="mail" color='teal' />
      <span>{t('info.email')}</span>
    </div>
    </div>
  </div>
  <div className="side-bar-image" style={{position:"relative"}}>
    <Image src="/assets/images/sidebar-ad.png" style={{width:"100%" }}/>
    <div style={{position:"absolute", bottom:20,display:"flex",width:"100%",justifyContent:"space-evenly"}}> 
      <a style={{padding:"0 0.2em 0 0.4em"}} href={links.ios}><Image src="/assets/images/appstore-wh.png" /></a>
      <a style={{padding:"0 0.4em 0 0.2em"}} href={links.android}><Image src="/assets/images/google-wh.png" /></a>
     </div>
  </div>
</>
));